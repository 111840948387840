import { combineReducers } from 'redux';
import hiddenItemsReducer from './hiddenItems/hiddenItemsReducer';
import mobileWizardReducer from './mobileWizard/mobileWizardReducer';
import mobileKeyboardReducer from './mobileKeyboard/mobileKeyboardReducer';
import mobileQabReducer from './qab/mobileQabReducer';
import introsReducer from './intros/introsReducer';
import { mobileInitialState } from './initialState';

export default combineReducers({
  isHiddenItemsCounterVisible: (
    isCounterVisible = mobileInitialState.isHiddenItemsCounterVisible,
  ) => isCounterVisible,
  reAddedHiddenElement: (
    reAddedHiddenElement = mobileInitialState.reAddedHiddenElement,
  ) => reAddedHiddenElement,
  hiddenItems: hiddenItemsReducer,
  mobileWizard: mobileWizardReducer,
  mobileKeyboard: mobileKeyboardReducer,
  qab: mobileQabReducer,
  introsEnabled: introsReducer,
});
