import _ from 'lodash';
import constants from './constants';
import getKeyFromAppId from './getKeyFromAppId';
import type { EditorState } from '@/stateManagement';
import type {
  UserPreferencesState,
  UserPreferencesType,
} from './userPreferencesReducer';

const getSiteId = (state: EditorState) => state.userPreferences.siteId;
const getPreferences =
  <TType extends UserPreferencesType>(typeId: TType) =>
  (state: EditorState): UserPreferencesState[TType] =>
    state.userPreferences[typeId];
const arePreferencesEmpty =
  (typeId: UserPreferencesType) => (state: EditorState) =>
    _.isEmpty(state.userPreferences[typeId]);
const dontSaveUserPrefs = (state: EditorState) =>
  state.userPreferences.dontSaveUserPrefs;
const getGlobalPrefFetchStatus = (state: EditorState) =>
  state.userPreferences.globalPrefFetchStatus;
const getSitePrefFetchStatus = (state: EditorState) =>
  state.userPreferences.sitePrefFetchStatus;
const getSitePreferences = (state: EditorState) => state.userPreferences.site;
const getGlobalPreferences = (state: EditorState) =>
  state.userPreferences.global;
const getSessionPreferences = (state: EditorState) =>
  state.userPreferences.session;

const getSiteUserPrefsForApp = (appId: AnyFixMe) => (state: EditorState) =>
  _.transform(
    getSitePreferences(state),
    (result: AnyFixMe, valueWrapper: { value: unknown }, key: string) => {
      const splitKey = key.split('_');
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/replace
      const cleanKey = _.replace(key, /editorAppId_/g, '');
      if (splitKey[0] === appId) {
        result[cleanKey] = valueWrapper.value;
      }
    },
    {},
  );

const getAllSiteUserPreferences = (state: EditorState) =>
  getSiteUserPrefsForApp(constants.EDITOR_APP_ID)(state);

const getSiteUserPreferences =
  <TValue = unknown>(key: string, appId?: string) =>
  (state: EditorState) => {
    appId = appId || constants.EDITOR_APP_ID;
    if (!_.isString(key)) {
      throw new Error(
        `Getting a user preference for the site expects a key of type string but received: ${key}`,
      );
    }

    const appKey = getKeyFromAppId(appId, key);
    const sitePreferences = getSitePreferences(state);
    return sitePreferences[appKey] && (sitePreferences[appKey].value as TValue);
  };

const getAllGlobalUserPreferences = (state: EditorState) => {
  const globalPreferences = getGlobalPreferences(state);
  if (globalPreferences === null) {
    console.error('Global user preferences failed to load!');
    return null;
  }

  return globalPreferences;
};

const getGlobalUserPreferences =
  <TValue = unknown>(key: string) =>
  (state: EditorState) => {
    const globalPreferences = getGlobalPreferences(state);
    if (globalPreferences === null) {
      console.error('Global user preferences failed to load!');
      return null;
    }

    if (!_.isString(key)) {
      throw new Error(
        `Getting a global user preference expects a key of type string but received: ${key}`,
      );
    }

    return globalPreferences[key] as TValue;
  };

const getSessionUserPreferences =
  <TValue = unknown>(key: string) =>
  (state: EditorState): TValue => {
    if (!_.isString(key)) {
      throw new Error(
        `Getting a session user preference expects a key of type string but received: ${key}`,
      );
    }

    return getSessionPreferences(state)[key] as TValue;
  };

const getAllSessionUserPreferences = (state: EditorState) =>
  getSessionPreferences(state);

const getMediaPath = (id: string) => (state: EditorState) =>
  getSessionUserPreferences(`last_media_path_${id}`)(state) || '';

const getUserPreferences = (typeId: UserPreferencesType) => {
  switch (typeId) {
    case 'site':
      return getSiteUserPreferences;
    case 'session':
      return getSessionUserPreferences;
    case 'global':
      return getGlobalUserPreferences;
  }
};

const getRegisteredSitePrefCallbacks = (state: EditorState) =>
  state.userPreferences.sitePreferencesFetchCallbacks;

const getSitePreferencesFetchDone = (state: EditorState): boolean =>
  state.userPreferences.sitePreferencesFetchDone;

export {
  getSiteId,
  getPreferences,
  arePreferencesEmpty,
  dontSaveUserPrefs,
  getAllGlobalUserPreferences,
  getGlobalUserPreferences,
  getAllSiteUserPreferences,
  getSiteUserPreferences,
  getAllSessionUserPreferences,
  getSessionUserPreferences,
  getUserPreferences,
  getMediaPath,
  getGlobalPrefFetchStatus,
  getSitePrefFetchStatus,
  getRegisteredSitePrefCallbacks,
  getSitePreferencesFetchDone,
};
