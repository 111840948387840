// @ts-nocheck
import * as selection from '../selection/selection';
import { vectorImageUtils } from '@wix/santa-editor-utils';
const selectionSelectors = selection.selectors;

const replaceVectorImage =
  (title, svgId) =>
  (dispatch, getState, { editorAPI }) => {
    const selectedComponent = selectionSelectors.getSelectedCompsRefs(
      getState(),
    );
    const compData = editorAPI.components.data.get(selectedComponent);
    vectorImageUtils.replaceVectorImage(
      editorAPI,
      selectedComponent,
      compData,
      title,
      svgId,
    );
  };

const changeVectorImage =
  (category, defaultPath, callback) =>
  (dispatch, getState, { editorAPI }) => {
    const selectedComponent = selectionSelectors.getSelectedCompsRefs(
      getState(),
    );
    editorAPI.mediaServices.changeVectorImage(
      selectedComponent,
      category,
      defaultPath,
      callback,
    );
  };

const vectorImage = {
  replace: replaceVectorImage,
  change: changeVectorImage,
};

export { vectorImage };
