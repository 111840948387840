import constants from '@/constants';
import type { EditorState } from '@/stateManagement';
import experiment from 'experiment';
import _ from 'lodash';
import { createSelector } from 'reselect';
import type { LeftBarItem } from './leftBarData';
import { getDesktopData, getMobileData } from './leftBarData';
import multiLingualSelectors from '../multilingual/multilingualSelectors';
import { type PanelProps, StageBehaviour } from '../panels/types';
import { STAGE_BEHAVIOUR_PRESET } from '../panels/stageBehaviourPreset';
import { sections } from '@/util';
import { allowed } from '@/editorRestrictions';

const getLeftBar = (state: EditorState) => state.leftBar;

const isMobileEditor = (state: EditorState) => state.isMobileEditor;

const getDeviceType = createSelector(isMobileEditor, (mobileEditor) =>
  mobileEditor ? 'mobile' : 'desktop',
);

const isCollapsed = createSelector(
  getLeftBar,
  isMobileEditor,
  (leftBar, mobileEditor) =>
    _.get(leftBar, mobileEditor ? 'isMobileCollapsed' : 'isCollapsed'),
);

const isHighlighted = createSelector(
  getLeftBar,
  (leftBar) => leftBar.isHighlighted,
);

const getCollapseOnClick = createSelector(
  getLeftBar,
  (leftBar) => leftBar.collapseOnClick,
);

const getDesktopSuperAppsCount = (state: EditorState) =>
  state.leftBar.superApps.desktop.length;

const isCollapseOnClick = (collapseOnClick: AnyFixMe, panelName: AnyFixMe) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  _.includes(collapseOnClick || [], panelName);

const filterPopUpModeItems = (leftBarItems: AnyFixMe) => {
  const panelNamesToExclude = [
    constants.ROOT_COMPS.LEFTBAR.BLOG_PANEL_NAME,
    constants.ROOT_COMPS.LEFTBAR.BACKGROUND_PANEL_NAME,
    constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
    constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME,
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(
    leftBarItems,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    (panel) => !_.includes(panelNamesToExclude, panel.panelName),
  );
};

const filterTranslationModeItems = (leftBarItems: AnyFixMe) => {
  const panelNamesToInclude = [
    constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
    constants.ROOT_COMPS.LEFTBAR.BACKGROUND_PANEL_NAME,
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(leftBarItems, (panel) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(panelNamesToInclude, panel.panelName),
  );
};

const removeBackgroundPanelIfNeeded = (
  leftBarItems: AnyFixMe,
  isMobile: boolean,
) => {
  const panelNamesToRemove = [
    constants.ROOT_COMPS.LEFTBAR.BACKGROUND_PANEL_NAME,
  ];

  if (isMobile && !experiment.isOpen('se_designPanelMobile')) {
    return leftBarItems;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.reject(leftBarItems, (panel) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(panelNamesToRemove, panel.panelName),
  );
};

const getPatchedItems = (state: EditorState) => state.leftBar.patchedItems;
const selectDesktopItemPatches = (state: EditorState) =>
  getPatchedItems(state).desktop;
const selectMobileItemPatches = (state: EditorState) =>
  getPatchedItems(state).mobile;

const applyPatches = (
  initialData: LeftBarItem[],
  patches: Partial<Record<string, Partial<LeftBarItem>>>,
) =>
  initialData.map((leftBarItem) => {
    const patch = patches[leftBarItem.key];

    return patch
      ? { ...leftBarItem, ..._.omitBy(patch, _.isUndefined) }
      : leftBarItem;
  });

const selectDesktopItems = createSelector(
  [getDesktopData, selectDesktopItemPatches],
  applyPatches,
);

const selectMobileItems = createSelector(
  [getMobileData, selectMobileItemPatches],
  applyPatches,
);

const getMenuItems = (
  state: EditorState,
  isPopUpMode: boolean,
): LeftBarItem[] => {
  let leftBarItems: LeftBarItem[] = [];
  const isTranslationMode = multiLingualSelectors.isTranslating(state);
  const { superApps } = state.leftBar;
  const additionalButtons: AnyFixMe = [];
  const areSuperAppsButtonsAllowed = allowed('left-bar_super-apps.visible');

  if (state.isMobileEditor) {
    let items = selectMobileItems(state);
    if (sections.isSectionsEnabled()) {
      const hiddenControlsKey = 'PAGE_LAYOUT_OPTIMIZER';
      items = items.filter((item) => item.key !== hiddenControlsKey);
    }
    const superAppsMobileIfNeeded = areSuperAppsButtonsAllowed
      ? _.sortBy(_.cloneDeep(superApps.mobile), 'order')
      : [];
    leftBarItems = [...items, ...additionalButtons, ...superAppsMobileIfNeeded];
  } else {
    const items = selectDesktopItems(state);
    const superAppsDesktopIfNeeded = areSuperAppsButtonsAllowed
      ? _.sortBy(_.cloneDeep(superApps.desktop), 'order')
      : [];

    leftBarItems = [
      ...items,
      ...additionalButtons,
      ...superAppsDesktopIfNeeded,
    ];
  }

  leftBarItems = isPopUpMode
    ? filterPopUpModeItems(leftBarItems)
    : leftBarItems;

  leftBarItems = isTranslationMode
    ? filterTranslationModeItems(leftBarItems)
    : removeBackgroundPanelIfNeeded(leftBarItems, state.isMobileEditor);

  leftBarItems.forEach((item, i) => {
    item.panelProps = {
      ...item.panelProps,
      panelIndex: i,
    };
    item.collapseOnClick = isCollapseOnClick(
      getCollapseOnClick(state),
      item.panelName,
    );
  });

  return leftBarItems.filter(Boolean);
};

export const shouldPanelShrinkStage = (
  state: EditorState,
  openedPanelName: string,
  isPopUpMode: boolean,
): boolean => {
  if (!openedPanelName) {
    return false;
  }

  const isOpenedPanel = ({ panelName }: LeftBarItem) =>
    openedPanelName === panelName;

  return (
    getMenuItems(state, isPopUpMode).find(isOpenedPanel)?.panelProps
      ?.stageBehaviour?.open === StageBehaviour.Shrink || false
  );
};

export const getPanelStageBehaviour = (
  state: EditorState,
  openedPanelName: string,
  isPopUpMode: boolean,
): PanelProps['stageBehaviour'] => {
  if (!openedPanelName) {
    return STAGE_BEHAVIOUR_PRESET.DEFAULT;
  }

  const isOpenedPanel = ({ panelName }: LeftBarItem) =>
    openedPanelName === panelName;

  const defaultStageBehaviour = sections.isSectionsEnabled()
    ? STAGE_BEHAVIOUR_PRESET.EXIT
    : STAGE_BEHAVIOUR_PRESET.DEFAULT;

  return (
    getMenuItems(state, isPopUpMode).find(isOpenedPanel)?.panelProps
      ?.stageBehaviour || defaultStageBehaviour
  );
};

const getNotifications = (state: EditorState) => state.leftBar.notifications;

const getNotificationsCount = (state: EditorState, panelName: string) =>
  state.leftBar.notifications[panelName];

const getOverrideItems = (state: EditorState, isMobile: boolean) =>
  _.get(state.leftBar.overriddenButtons, isMobile ? 'mobile' : 'desktop');

const getOpenedPanel = (state: AnyFixMe) => state.leftBar.openedPanel;

export {
  getLeftBar,
  isCollapsed,
  isHighlighted,
  getCollapseOnClick,
  isMobileEditor,
  getDeviceType,
  getDesktopSuperAppsCount,
  getMenuItems,
  getNotifications,
  getNotificationsCount,
  getOverrideItems,
  getOpenedPanel,
  removeBackgroundPanelIfNeeded,
};
