import constants from '@/constants';
import { events } from '@/coreBi';
import {
  addPanel as addPanelUtil,
  bi,
  fedopsLogger,
  isCustomMenusEnabled,
  isAdvancedMenuOpen,
  sections,
  workspace,
} from '@/util';
import experiment from 'experiment';
import type { PanelProps } from '../panels/types';
import { STAGE_BEHAVIOUR_PRESET } from '../panels/stageBehaviourPreset';
import { allowed } from '@/editorRestrictions';

const { LEFTBAR } = constants.ROOT_COMPS;

const isNewWorkspace = workspace.isNewWorkspaceEnabled();
const isNewMobileDesignPanel = experiment.isOpen('se_designPanelMobile');

interface TooltipItemDef {
  id: string;
  value: string;
  width: string;
}

export interface LeftBarItem {
  key: string;
  panelName: string;
  panelProps: PanelProps;
  label: string;
  className: string;
  symbolName: string;
  hintProps?: {
    id: string;
  };
  tooltips?: TooltipItemDef[];
  collapseOnClick?: boolean;
}

type Values<T> = T[keyof T];

const getNewAddPanelButtonClassName = () => {
  return sections.isSectionsEnabled()
    ? 'add-panel add-panel-sections'
    : 'add-panel';
};

const getNewAddPanelButtonSymbolName = () => {
  if (sections.isSectionsEnabled()) return 'leftBarButtonAddSections';
  return isNewWorkspace ? 'leftBarButtonAdd' : 'leftBarAddPanel';
};

function createLeftBarItemsMap<T extends { [name: string]: LeftBarItem }>(
  cfg: T,
) {
  return cfg;
}

const getDefaultDesktopItems = () => {
  const toggleZoomOutStageBehaviour: PanelProps['stageBehaviour'] =
    sections.isSectionsEnabled()
      ? STAGE_BEHAVIOUR_PRESET.TOGGLE
      : STAGE_BEHAVIOUR_PRESET.DEFAULT;

  const exitZoomOutStageBehaviour: PanelProps['stageBehaviour'] =
    sections.isSectionsEnabled()
      ? STAGE_BEHAVIOUR_PRESET.EXIT
      : STAGE_BEHAVIOUR_PRESET.DEFAULT;

  const addPanelButton: LeftBarItem = {
    key: LEFTBAR.ADD_ID,
    panelName: LEFTBAR.ADD_PANEL_NAME,
    panelProps: {
      origin: LEFTBAR.OPEN_ORIGIN,
      openPanelInteraction: fedopsLogger.mapInteraction(
        fedopsLogger.INTERACTIONS.OPEN_ADD_PANEL,
      ),
      firstPresetSectionLoadInteraction: fedopsLogger.mapInteraction(
        fedopsLogger.INTERACTIONS.ADD_PANEL
          .OLD_ADD_PANEL_FIRST_PRESET_SECTION_LOAD,
      ),
      stageBehaviour: exitZoomOutStageBehaviour,
    },
    label: sections.isSectionsEnabled()
      ? LEFTBAR.ADD_ELEMENT_LABEL
      : LEFTBAR.ADD_LABEL,
    className: 'add-panel',
    symbolName: isNewWorkspace ? 'leftBarButtonAdd' : 'leftBarAddPanel',
    hintProps: { id: 'AddPanel' },
  };

  const newAddPanelButton: LeftBarItem = {
    key: LEFTBAR.ADD_ID,
    panelName: LEFTBAR.NEW_ADD_PANEL_NAME,
    panelProps: {
      origin: LEFTBAR.OPEN_ORIGIN,
      openPanelInteraction: fedopsLogger.mapInteraction(
        fedopsLogger.INTERACTIONS.ADD_PANEL.OPEN_NEW,
      ),
      firstPresetSectionLoadInteraction: fedopsLogger.mapInteraction(
        fedopsLogger.INTERACTIONS.ADD_PANEL
          .NEW_ADD_PANEL_FIRST_PRESET_SECTION_LOAD,
      ),
      stageBehaviour: exitZoomOutStageBehaviour,
    },
    label: sections.isSectionsEnabled()
      ? LEFTBAR.ADD_ELEMENT_LABEL
      : LEFTBAR.ADD_LABEL,
    className: getNewAddPanelButtonClassName(),
    symbolName: getNewAddPanelButtonSymbolName(),
    hintProps: { id: 'AddPanel' },
    // Exists here because we want to show tooltips from mobile tools panel
    // doesn't work if added to mobile add panel button instead
    tooltips: experiment.isOpen('se_mobileToolsInAddPanel')
      ? [
          {
            id: 'mobileElementsPanel_quickActionBar_afterRemove',
            value: 'mobile_quick_actions_hide_tooltip',
            width: '240px',
          },
          {
            id: 'BacktoTop_Button_MobileTools_Postdelete_Tooltip',
            value: 'BacktoTop_Button_MobileTools_Postdelete_Tooltip',
            width: '240px',
          },
        ]
      : undefined,
  };

  return createLeftBarItemsMap({
    pages: {
      key: LEFTBAR.PAGES_ID,
      panelName: LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
      panelProps: {
        openPanelBiEvent: events.pages.click_on_pages_panel_open,
        openPanelBiParams: {
          origin: LEFTBAR.OPEN_ORIGIN,
        },
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
        ),
        closePanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
        ),
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.PAGES_LABEL,
      className: 'pages-panel-pp',
      symbolName: isNewWorkspace
        ? 'leftBarButtonPages'
        : 'leftBarPagesPanelManager',
    },
    pagesAndMenus: {
      key: LEFTBAR.MENUS_AND_PAGES_ID,
      panelName: LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
      panelProps: {
        openPanelBiEvent: events.pages.click_on_pages_panel_open,
        openPanelBiParams: {
          origin: LEFTBAR.OPEN_ORIGIN,
        },
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
        ),
        closePanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
        ),
        stageBehaviour: exitZoomOutStageBehaviour,
        origin: LEFTBAR.OPEN_ORIGIN,
      },
      label: isAdvancedMenuOpen()
        ? LEFTBAR.ADVANCED_MENU_PAGES_LABEL
        : LEFTBAR.MENUS_AND_PAGES_LABEL,
      className: 'pages-panel-pp',
      symbolName: isNewWorkspace
        ? 'leftBarButtonPages'
        : 'leftBarPagesPanelManager',
    },
    addSection: {
      key: LEFTBAR.ADD_SECTION,
      panelName: LEFTBAR.ADD_SECTION_PANEL_NAME,
      panelProps: {
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_OPEN,
        ),
        origin: LEFTBAR.OPEN_ORIGIN,
        stageBehaviour: toggleZoomOutStageBehaviour,
        emptyStateSectionReplacement: true,
        emptyStateBlankSectionIndex: 1,
      },
      label: LEFTBAR.ADD_SECTION_LABEL,
      className: 'add-section-panel',
      symbolName: 'leftBarButtonAddSection',
    },
    add: addPanelUtil.isNewPanelEnabled() ? newAddPanelButton : addPanelButton,
    background: {
      key: LEFTBAR.BACKGROUND_ID,
      panelName: LEFTBAR.BACKGROUND_PANEL_NAME,
      panelProps: {
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.BACKGROUND_LABEL,
      className: 'bg-panel',
      symbolName: isNewWorkspace
        ? 'leftBarButtonBackground'
        : 'leftBarBackgroundPanel',
      hintProps: {
        id: 'ChangeBackgrounds',
      },
    },
    appManager: {
      key: LEFTBAR.APP_MANAGER_PANEL_NAME,
      panelName: LEFTBAR.APP_MANAGER_PANEL_NAME,
      panelProps: {
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.APP_MANAGER_LABEL,
      className: sections.isSectionsEnabled()
        ? 'app-manager-panel-new'
        : 'app-manager-panel',
      symbolName: isNewWorkspace
        ? 'leftBarButtonAppManager'
        : 'leftBarAppManagerIcon',
    },
    addApps: {
      key: LEFTBAR.ADD_APPS_ID,
      panelName: LEFTBAR.ADD_APPS_PANEL_NAME,
      panelProps: {
        urlParams: {
          openMarketOrigin: 'market_button',
        },
        openPanelBiEvent: bi.events.OPEN_MARKET_CLICK,
        openPanelBiParams: {
          referral_info: 'market_button',
        },
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.ADD_APPS_LABEL,
      className: 'app-market-panel',
      symbolName: isNewWorkspace
        ? 'leftBarButtonAppMarket'
        : 'leftBarAppMarketPanel-D',
      hintProps: {
        id: 'AppMarket',
      },
    },
    mediaManager: {
      key: LEFTBAR.MEDIA_MANAGER_ID,
      panelName: LEFTBAR.MEDIA_MANAGER_PANEL_NAME,
      panelProps: {
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.MEDIA_LABEL,
      className: 'media-manager-panel-icon-c',
      symbolName: isNewWorkspace ? 'leftBarButtonMedia' : 'leftBarMediaPanelC',
    },
    design: {
      key: LEFTBAR.DESIGN_PANEL_ID,
      panelName: LEFTBAR.DESIGN_PANEL_NAME,
      panelProps: {
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.DESIGN_PANEL.OPEN_PANEL,
        ),
        closePanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.DESIGN_PANEL.CLOSE_PANEL,
        ),
        stageBehaviour: toggleZoomOutStageBehaviour,
      },
      label: LEFTBAR.DESIGN_PANEL_LABEL,
      className: 'design-panel-button',
      symbolName: isNewWorkspace ? 'leftBarButtonTheme' : 'leftBarThemePanel',
    },
    moreOptions: {
      key: LEFTBAR.MORE_OPTIONS_ID,
      panelName: LEFTBAR.MORE_OPTIONS_PANEL_NAME,
      panelProps: {
        stageBehaviour: exitZoomOutStageBehaviour,
      },
      label: LEFTBAR.MORE_OPTIONS_LABEL,
      className: 'more-options',
      symbolName: 'leftBarButtonMoreOptions',
      hintProps: {
        id: 'WorkspaceModesLeftPanel',
      },
    },
  });
};

const getDefaultMobileItems = () => {
  return createLeftBarItemsMap({
    pagesAndMenus: {
      key: LEFTBAR.MENUS_AND_PAGES_ID,
      panelName: LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
      panelProps: {
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
        ),
        closePanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
        ),
      },
      label: isCustomMenusEnabled()
        ? LEFTBAR.PAGES_LABEL
        : LEFTBAR.MENUS_AND_PAGES_LABEL,
      className: 'pages-panel-pp',
      symbolName: isNewWorkspace
        ? 'leftBarButtonPages'
        : 'leftBarPagesPanelManager',
    },
    background: {
      key: LEFTBAR.BACKGROUND_ID,
      panelName: LEFTBAR.BACKGROUND_PANEL_NAME,
      panelProps: {},
      label: LEFTBAR.BACKGROUND_LABEL,
      className: 'bg-panel',
      symbolName: isNewWorkspace
        ? 'leftBarButtonBackground'
        : 'leftBarBackgroundPanel',
      hintProps: {
        id: 'ChangeBackgrounds',
      },
    },
    mobileTools: {
      key: LEFTBAR.MOBILE_TOOLS_ID,
      panelName: LEFTBAR.MOBILE_TOOLS_PANEL_NAME,
      panelProps: {},
      label: LEFTBAR.MOBILE_TOOLS_LABEL,
      className: 'mobile-elements-panel',
      symbolName: isNewWorkspace ? 'leftBarButtonTools' : 'mobileView',
      tooltips: [
        {
          id: 'mobileElementsPanel_quickActionBar_afterRemove',
          value: 'mobile_quick_actions_hide_tooltip',
          width: '240px',
        },
        {
          id: 'BacktoTop_Button_MobileTools_Postdelete_Tooltip',
          value: 'BacktoTop_Button_MobileTools_Postdelete_Tooltip',
          width: '240px',
        },
      ],
    },
    hiddenElements: {
      key: LEFTBAR.HIDDEN_ELEMENTS_ID,
      panelName: LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
      panelProps: {},
      label: LEFTBAR.HIDDEN_ELEMENTS_LABEL,
      className: 'hidden-items-panel',
      symbolName: isNewWorkspace ? 'leftBarButtonHidden' : 'hiddenComp',
      hintProps: {
        id: 'MobileEditorHiddenElements',
      },
    },
    layoutOptimizer: {
      key: LEFTBAR.PAGE_LAYOUT_OPTIMIZER_ID,
      panelName: LEFTBAR.PAGE_LAYOUT_OPTIMIZER_PANEL_NAME,
      panelProps: {},
      label: LEFTBAR.PAGE_LAYOUT_OPTIMIZER_LABEL,
      className: 'layout-optimizer-panel',
      symbolName: isNewWorkspace
        ? 'leftBarButtonLayout'
        : 'mobile_left_bar_optimize_layout',
      hintProps: {
        id: 'MobileLayoutOptimizer',
      },
    },
    mobileAddPanel: {
      key: LEFTBAR.ADD_ID,
      panelName: LEFTBAR.NEW_ADD_PANEL_NAME,
      panelProps: {
        origin: LEFTBAR.OPEN_ORIGIN,
      },
      label: LEFTBAR.ADD_MOBILE_LABEL,
      className: 'add-panel',
      symbolName: getNewAddPanelButtonSymbolName(),
      hintProps: {
        id: 'AddPanel',
      },
    },
    design: {
      key: LEFTBAR.DESIGN_PANEL_ID,
      panelName: LEFTBAR.DESIGN_PANEL_NAME,
      panelProps: {
        openPanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.DESIGN_PANEL.OPEN_PANEL,
        ),
        closePanelInteraction: fedopsLogger.mapInteraction(
          fedopsLogger.INTERACTIONS.DESIGN_PANEL.CLOSE_PANEL,
        ),
      },
      label: LEFTBAR.DESIGN_PANEL_LABEL,
      className: 'design-panel-button',
      symbolName: isNewWorkspace ? 'leftBarButtonTheme' : 'leftBarThemePanel',
    },
    moreOptions: {
      key: LEFTBAR.MORE_OPTIONS_ID,
      panelName: LEFTBAR.MORE_OPTIONS_PANEL_NAME,
      panelProps: {},
      label: LEFTBAR.MORE_OPTIONS_LABEL,
      className: 'more-options',
      symbolName: 'leftBarButtonMoreOptions',
      hintProps: {
        id: 'WorkspaceModesLeftPanel',
      },
    },
  });
};

export const getDesktopData = () => {
  const items = getDefaultDesktopItems();

  const pagesItem = isCustomMenusEnabled() ? items.pages : items.pagesAndMenus;

  const oldWorkspaceDesktopData = [
    pagesItem,
    items.background,
    allowed('left-bar_add.visible') && items.add,
    allowed('left-bar_add-apps.visible') && items.addApps,
    items.design,
    items.appManager,
    allowed('left-bar_media-manager.visible') && items.mediaManager,
  ].filter(Boolean);

  const newWorkspaceDesktopData = [
    allowed('left-bar_add.visible') && items.add,
    pagesItem,
    items.background,
    items.design,
    allowed('left-bar_add-apps.visible') && items.addApps,
    items.appManager,
    allowed('left-bar_media-manager.visible') && items.mediaManager,
  ].filter(Boolean);

  if (sections.isSectionsEnabled()) {
    return [
      allowed('left-bar_add.visible') && items.add,
      items.addSection,
      pagesItem,
      items.background,
      items.design,
      allowed('left-bar_add-apps.visible') && items.addApps,
      items.appManager,
      allowed('left-bar_media-manager.visible') && items.mediaManager,
      allowed('left-bar_more-options.visible') && items.moreOptions,
    ].filter(Boolean);
  }

  return workspace.isNewWorkspaceEnabled()
    ? newWorkspaceDesktopData
    : oldWorkspaceDesktopData;
};

export const getMobileData = (): LeftBarItem[] => {
  const {
    pagesAndMenus,
    background,
    mobileTools,
    hiddenElements,
    layoutOptimizer,
    mobileAddPanel,
    design,
    moreOptions,
  } = getDefaultMobileItems();

  let oldWorkspaceMobileData: LeftBarItem[] = [
    pagesAndMenus,
    !isNewMobileDesignPanel && background,
    isNewMobileDesignPanel && design,
    mobileTools,
    allowed('left-bar_hidden-elements.visible') && hiddenElements,
    layoutOptimizer,
    allowed('left-bar_more-options.visible') && moreOptions,
  ].filter(Boolean);

  let newWorkspaceMobileData: LeftBarItem[] = [
    pagesAndMenus,
    !isNewMobileDesignPanel && background,
    isNewMobileDesignPanel && design,
    mobileTools,
    allowed('left-bar_hidden-elements.visible') && hiddenElements,
    layoutOptimizer,
    allowed('left-bar_more-options.visible') && moreOptions,
  ].filter(Boolean);

  if (
    addPanelUtil.isNewPanelEnabled() &&
    allowed('left-bar_mobile-add.visible')
  ) {
    oldWorkspaceMobileData.splice(2, 0, mobileAddPanel);
    newWorkspaceMobileData.unshift(mobileAddPanel);
  }

  if (experiment.isOpen('se_mobileToolsInAddPanel')) {
    oldWorkspaceMobileData = oldWorkspaceMobileData.filter(
      (i) => i.key !== LEFTBAR.MOBILE_TOOLS_ID,
    );

    newWorkspaceMobileData = newWorkspaceMobileData.filter(
      (i) => i.key !== LEFTBAR.MOBILE_TOOLS_ID,
    );
  }
  return workspace.isNewWorkspaceEnabled()
    ? newWorkspaceMobileData
    : oldWorkspaceMobileData;
};

export type LeftBarDesktopItemKey = Values<
  ReturnType<typeof getDefaultDesktopItems>
>['key'];
export type LeftBarMobileItemKey = Values<
  ReturnType<typeof getDefaultMobileItems>
>['key'];
