// @ts-nocheck
import * as actionTypes from './appsStoreActionTypes';

const updateAppsStore = (appDefinitionId, partialState) => ({
  type: actionTypes.UPDATE_APP_STORE,
  appDefinitionId,
  partialState,
});

export { updateAppsStore };
