// @ts-nocheck
import _ from 'lodash';

import * as highlightsActions from '../highlights/highlightsActions';
import * as servicesActions from '../services/servicesActions';
import * as domMeasurements from '../domMeasurements/domMeasurements';

const MARGIN_FROM_SITE_EDGE = 30;

const { getStageRect } = domMeasurements.selectors;

const getScrollCoordinateToShowComponent = (layout, stageRect) => {
  const componentTopIsAboveViewport = layout.y < stageRect.top;
  const componentTopIsBelowViewport = layout.y > stageRect.bottom;
  const componentTopIsVisible =
    layout.y > stageRect.top && layout.y < stageRect.bottom;
  const componentBottomIsVisible = layout.y + layout.height < stageRect.bottom;
  const componentIsBiggerThenViewport =
    layout.height > stageRect.bottom - stageRect.top;

  const getScrollTopCoordinate = (compLayout) =>
    Math.max(compLayout.y - MARGIN_FROM_SITE_EDGE, 0);
  const getScrollBottomCoordinate = (compLayout, viewport) =>
    viewport.top +
    (compLayout.y - viewport.bottom) +
    compLayout.height +
    MARGIN_FROM_SITE_EDGE;

  if (componentTopIsAboveViewport) {
    return getScrollTopCoordinate(layout);
  } else if (componentTopIsVisible && componentIsBiggerThenViewport) {
    return getScrollTopCoordinate(layout);
  } else if (
    componentTopIsVisible &&
    !componentBottomIsVisible &&
    !componentIsBiggerThenViewport
  ) {
    return getScrollBottomCoordinate(layout, stageRect);
  } else if (componentTopIsBelowViewport && !componentIsBiggerThenViewport) {
    return getScrollBottomCoordinate(layout, stageRect);
  } else if (componentTopIsBelowViewport && componentIsBiggerThenViewport) {
    return getScrollTopCoordinate(layout);
  }

  return stageRect.top;
};

const locateComponentAndHighlight =
  (compRef, isHover, type?: string) =>
  (dispatch, getState, { editorAPI }) => {
    const layout = highlightsActions.getComponentLayout(compRef, editorAPI);
    dispatch(
      highlightsActions.handleComponentHighlight(
        compRef,
        layout,
        isHover,
        type,
      ),
    );

    const stageRect = getStageRect(getState());
    const newScrollPosition = getScrollCoordinateToShowComponent(
      layout,
      stageRect,
    );

    if (newScrollPosition !== stageRect.top) {
      dispatch(
        servicesActions.setState({
          animateScrollTo: {
            position: { x: 0, y: newScrollPosition },
            duration: 0.5,
          },
        }),
      );
    }
  };

const locateComponentAndHighlightMultiComponents =
  (compRefs, isHover) =>
  (dispatch, getState, { dsRead }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const compRefsAndLayouts = _.map(compRefs, (compRef) => ({
      compRef,
      layout: dsRead.components.layout.getRelativeToScreen(compRef),
    }));
    dispatch(
      highlightsActions.handleMultiComponentHighlight(
        compRefsAndLayouts,
        isHover,
      ),
    );
  };

export {
  locateComponentAndHighlight,
  locateComponentAndHighlightMultiComponents,
};
