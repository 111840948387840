import * as hiddenItemsActions from './hiddenItems/hiddenItemsActions';
import * as interactionsHiddenItemsActions from './interactionsHiddenItems/interactionsHiddenItemsActions';
import * as mobileWizardActions from './mobileWizard/mobileWizardActions';
import * as mobileKeyboardActions from './mobileKeyboard/mobileKeyboardActions';
import mobileQabActions from './qab/mobileQabActions';
import introsActions from './intros/introsActions';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as userPreferences from '../userPreferences/userPreferences';
import * as notificationsActions from '../notifications/notificationsActions';

const { getSiteUserPreferences } = userPreferences.selectors;
const { setSiteUserPreferences } = userPreferences.actions;

const { USER_PASTED_MOBILE_ONLY_COMP, USER_ADDED_COMPONENT_FROM_ADD_PANEL } =
  constants.USER_PREFS.MOBILE_EDITOR;

const setMobileOptimizedSite =
  (isMobileOptimizedSite: boolean) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { dsActions }: AnyFixMe) =>
    dsActions.mobile.enableOptimizedView(isMobileOptimizedSite);

const showCompAddedInMobileNotificationIfNeeded =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const userSeenMobileOnlyAddedNotification = getSiteUserPreferences(
      USER_ADDED_COMPONENT_FROM_ADD_PANEL,
    )(getState());
    if (!userSeenMobileOnlyAddedNotification) {
      dispatch(
        notificationsActions.showUserActionNotification({
          message: 'Notifications_Mobile_Add_Element_Text',
          title: 'Notifications_Mobile_Add_Element_Text',
          type: 'info',
          link: {
            caption: 'Notifications_Mobile_Add_Element_Link',
            onNotificationLinkClick: () => {
              editorAPI.panelManager.openHelpCenter(
                helpIds.MOBILE_ONLY.FIRST_MOBILE_ONLY_COMP,
              );
            },
          },
        }),
      );
      dispatch(
        setSiteUserPreferences(USER_ADDED_COMPONENT_FROM_ADD_PANEL, true),
      );
    }
  };

const showCompPastedInMobileNotificationIfNeeded =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const userSeenMobileOnlyAddedNotification = getSiteUserPreferences(
      USER_PASTED_MOBILE_ONLY_COMP,
    )(getState());
    if (!userSeenMobileOnlyAddedNotification) {
      dispatch(
        notificationsActions.showUserActionNotification({
          message: 'Notifications_Mobile_Copy_Element_Text',
          title: 'Notifications_Mobile_Copy_Element_Text',
          type: 'info',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick: () => {
              editorAPI.panelManager.openHelpCenter(
                helpIds.MOBILE_ONLY.FIRST_PASTE_MOBILE_ONLY_COMP,
              );
            },
          },
        }),
      );
      dispatch(setSiteUserPreferences(USER_PASTED_MOBILE_ONLY_COMP, true));
    }
  };

export {
  setMobileOptimizedSite,
  showCompAddedInMobileNotificationIfNeeded,
  showCompPastedInMobileNotificationIfNeeded,
  hiddenItemsActions as hiddenItems,
  interactionsHiddenItemsActions as interactionsHiddenItems,
  mobileWizardActions as mobileWizard,
  mobileKeyboardActions as mobileKeyboard,
  mobileQabActions as qab,
  introsActions as intros,
};
