import textActionTypes from './textActionTypes';
import type { Reducer } from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { WRichTextSettingsPanelProps } from '@/advancedStylePanel';

const {
  OPEN_TEXT_EDITOR,
  CLOSE_TEXT_EDITOR,
  SET_SELECTED_COMPONENT,
  SET_SELECTED_COLOR,
  LOAD_FROM_WIDGET_DESIGN,
  CLEAR_WIDGET_DESIGN_STATE,
} = textActionTypes;

interface WidgetDesignState {
  selectedComponents: CompRef[];
  panelProps: WRichTextSettingsPanelProps;
}

export interface TextState {
  isLinkPanelOpen: boolean;
  isTextEditorOpen: boolean;
  editStyleOnly: boolean;
  selectedComponent?: CompRef;
  selectedColor?: string;
  widgetDesign?: WidgetDesignState;
}

export const textInitialState: TextState = {
  isTextEditorOpen: false,
  editStyleOnly: false,
  selectedComponent: null,
  selectedColor: null,
  widgetDesign: null,
  isLinkPanelOpen: false,
};

export const textReducer: Reducer<TextState> = (
  text = textInitialState,
  action,
) => {
  switch (action.type) {
    case OPEN_TEXT_EDITOR:
      return Object.assign({}, text, {
        isTextEditorOpen: true,
        editStyleOnly: action.editStyleOnly,
        isLinkPanelOpen: action.isLinkPanelOpen,
      });
    case CLOSE_TEXT_EDITOR:
      return Object.assign({}, text, {
        isTextEditorOpen: false,
        isLinkPanelOpen: false,
      });
    case SET_SELECTED_COMPONENT:
      return Object.assign({}, text, {
        selectedComponent: action.selectedComponent,
      });
    case SET_SELECTED_COLOR:
      return Object.assign({}, text, {
        selectedColor: action.selectedColor,
      });
    case LOAD_FROM_WIDGET_DESIGN:
      return Object.assign({}, text, {
        selectedComponent: action.selectedComponents[0],
        widgetDesign: {
          selectedComponents: action.selectedComponents,
          panelProps: action.panelProps,
        },
      });
    case CLEAR_WIDGET_DESIGN_STATE:
      return Object.assign({}, text, {
        widgetDesign: {},
        selectedComponent: null,
      });
    default:
      return text;
  }
};
