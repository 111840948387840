const COLLAPSE_DESKTOP_MENU = 'collapse-desktop-menu';
const COLLAPSE_MOBILE_MENU = 'collapse-mobile-menu';
const HIGHLIGHT_MENU = 'highlight-menu';
const UNHIGHLIGHT_MENU = 'unhighlight-menu';
const EXPAND_BUTTON = 'expand-button';
const COLLAPSE_BUTTON = 'collapse-button';
const UPDATE_BUTTONS_BOUNDING_RECT = 'update-buttons-bounding-rect';
const SET_NOTIFICATION_COUNT = 'set-notification-count';
const INCREASE_NOTIFICATION_COUNT = 'increase-notification-count';
const DECREASE_NOTIFICATION_COUNT = 'decrease-notification-count';
const REMOVE_PANEL_NOTIFICATIONS = 'removePanelNotifications';
const UPDATE_DESKTOP_SUPERAPP_BUTTON = 'updateDesktopSuperappButton';
const UPDATE_MOBILE_SUPERAPP_BUTTON = 'updatemobileSuperappButton';
const REMOVE_SUPERAPP_BUTTON = 'removeSuperAppButton';
const OVERRIDE_LEFT_BAR = 'override-left-bar';
const PATCH_DESKTOP_LEFT_BAR_ITEMS = 'patch-desktop-left-bar-items';
const PATCH_MOBILE_LEFT_BAR_ITEMS = 'patch-mobile-left-bar-items';

export {
  COLLAPSE_DESKTOP_MENU,
  COLLAPSE_MOBILE_MENU,
  HIGHLIGHT_MENU,
  UNHIGHLIGHT_MENU,
  EXPAND_BUTTON,
  COLLAPSE_BUTTON,
  UPDATE_BUTTONS_BOUNDING_RECT,
  SET_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
  DECREASE_NOTIFICATION_COUNT,
  REMOVE_PANEL_NOTIFICATIONS,
  UPDATE_DESKTOP_SUPERAPP_BUTTON,
  UPDATE_MOBILE_SUPERAPP_BUTTON,
  REMOVE_SUPERAPP_BUTTON,
  OVERRIDE_LEFT_BAR,
  PATCH_DESKTOP_LEFT_BAR_ITEMS,
  PATCH_MOBILE_LEFT_BAR_ITEMS,
};
