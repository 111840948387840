import _ from 'lodash';
import constants from '@/constants';
import * as focusBoxDefinitions from './focusBoxDefinitions';
import * as platform from '../platform/platform';
import * as componentsSelectors from '../components/componentsSelectors';
import { sectionsSelectors } from '../sections/sections.selectors';
import * as componentMetaDataSelectors from '../componentMetaData/componentMetaDataSelectors';
import type { EditorState } from '../store/editorState';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import type { DSRead } from 'types/documentServices';

const { isPlatformShowFocusBox } = componentMetaDataSelectors;
const { isConnectedToDataBindingController } = platform.selectors;

const getSelectedCompsRefs = (state: EditorState) =>
  state.selection.selectedComponents;
const getSelectedCompRestrictions = (state: EditorState) =>
  _.isEmpty(getSelectedCompsRefs(state))
    ? {}
    : state.selection.compRestrictions;
const getSelectedComponentType = (state: EditorState, dsRead: DSRead) => {
  const selectedComps = getSelectedCompsRefs(state);
  return componentsSelectors.getCompTypeSuffix(selectedComps, dsRead);
};
const getSelectedCompsData = (state: EditorState, dsRead: DSRead) => {
  const selectedComps = getSelectedCompsRefs(state);
  return componentsSelectors.getCompsData(selectedComps, dsRead);
};

const isSelectedCompConnectedToDataBindingController = (
  state: EditorState,
  dsRead: DSRead,
) => {
  const selectedComps = getSelectedCompsRefs(state);
  if (selectedComps.length !== 1) {
    return false;
  }

  return isConnectedToDataBindingController(_.head(selectedComps), dsRead);
};

const getLastSelectionClickPos = (state: EditorState) =>
  state.selection.lastSelectionClickPos;
const getFocusedContainer = (state: EditorState) =>
  state.selection.focusedContainer;
const getFocusedNonAppWidgetContainer = (state: EditorState, dsRead: DSRead) =>
  componentsSelectors.getNonAppWidgetComp(getFocusedContainer(state), dsRead);
const getAppContainer = (state: EditorState) => state.selection.appContainer;
const getSelectedCompsDisplayName = (state: EditorState) =>
  state.selection.compDisplayName;

const getFocusedContainerTabDefinition = (state: EditorState) =>
  state.selection.focusBoxTabsDef;

const isFocusableMode = (modeDefinition: { type: string }) => {
  const FOCUSABLE_MODES = ['DEFAULT', 'HOVER'];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(FOCUSABLE_MODES, modeDefinition.type);
};

const isFocusableModeContainer = (compPointer: CompRef, dsRead: DSRead) => {
  const modes = dsRead.components.modes.getModes(compPointer);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  return !_.isEmpty(modes) && _.every(modes, isFocusableMode);
};

const getPlatformFocusedTabsDef = (
  comp: CompRef,
  dsRead: DSRead,
  getDisplayName: (compRef: CompRef) => string,
) => {
  const compType = componentsSelectors.getCompTypeSuffix(comp, dsRead);
  if (compType === 'StateBox') {
    const getStateBoxDefinition =
      focusBoxDefinitions.containerTypeToFocusBoxTabs.StateBox;
    return getStateBoxDefinition(comp, dsRead, { getDisplayName });
  }

  return {
    tabs: [
      {
        id: 'platform-tab',
        label: getDisplayName(comp),
        active: true,
      },
    ],
    type: constants.UI.NAVIGATION_TYPES.TABS,
    translate: false,
  };
};

const getContainerTabDefinition = (
  containerPointer: CompRef,
  dsRead: DSRead,
  state: EditorState,
  editorAPI: EditorAPI,
) => {
  const getDisplayName = editorAPI.components.getDisplayName;
  const getComponentFeature = editorAPI.components.features.get;

  if (isPlatformShowFocusBox(containerPointer, dsRead)) {
    return getPlatformFocusedTabsDef(containerPointer, dsRead, getDisplayName);
  }

  if (editorAPI.componentFocusMode.isEnabled()) {
    return {
      type: constants.UI.NAVIGATION_TYPES.TABS,
      tabs: [
        {
          id: 'component-focus-mode',
          label: getDisplayName(containerPointer),
        },
      ],
    };
  }

  const focusedContainerType = componentsSelectors.getCompTypeSuffix(
    containerPointer,
    dsRead,
  );
  const getTabsDef =
    focusBoxDefinitions.containerTypeToFocusBoxTabs[focusedContainerType];
  let tabsDef = null;
  if (_.isFunction(getTabsDef)) {
    tabsDef = getTabsDef(containerPointer, dsRead, {
      getDisplayName,
      getComponentFeature,
      editorAPI,
    });
  } else if (isFocusableModeContainer(containerPointer, dsRead)) {
    tabsDef = focusBoxDefinitions.getModefulContainerTabsDef();
  }
  return tabsDef;
};

const getSingleSelectedComponent = (state: EditorState) => {
  const selectedComps = getSelectedCompsRefs(state);
  return selectedComps.length === 1 ? selectedComps[0] : null;
};

const getAncestorOfSelectedWithInteraction = (state: EditorState) =>
  state.selection.ancestorOfSelectedWithInteraction;

const getLastClickType = (state: EditorState) => state.selection.lastClickType;

const isLastClickTypeRight = (state: EditorState) =>
  getLastClickType(state) === 'right';

const getPrevSelectedComponents = (state: EditorState) =>
  state.selection.prevSelectedComponents;

const shouldHideSelectionWhenStageActionsHovered = (editorAPI: EditorAPI) => {
  const isSectionActionsBarHovered =
    sectionsSelectors.getIsSectionActionsBarHovered(editorAPI.store.getState());

  return isSectionActionsBarHovered;
};

export {
  getSelectedCompsRefs,
  getSelectedComponentType,
  getSelectedCompRestrictions,
  getLastSelectionClickPos,
  getFocusedContainer,
  getFocusedNonAppWidgetContainer,
  getSelectedCompsData,
  getAncestorOfSelectedWithInteraction,
  getSelectedCompsDisplayName,
  getAppContainer,
  getFocusedContainerTabDefinition,
  getContainerTabDefinition,
  getSingleSelectedComponent,
  getPlatformFocusedTabsDef,
  getLastClickType,
  getPrevSelectedComponents,
  isLastClickTypeRight,
  isSelectedCompConnectedToDataBindingController,
  isFocusableModeContainer,
  shouldHideSelectionWhenStageActionsHovered,
};
