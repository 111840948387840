import {
  EditorPlatformHostIntegrationAPI,
  type AddAppData,
  type DependenciesDataDriver,
  type InstallerAppsData,
  type InstallerData,
  type InstallerAppsToInstall,
} from '@wix/editor-platform-host-integration-apis';
import * as panelsActions from '../../panels/panelsActions';
import { translate } from '@/i18n';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type { PanelResolveType } from '@/platformPanels';
import type {
  InstallAppCallbacks,
  InstallAppOptions,
  UpdateAppCallbacks,
} from './installTypes';
import type { EditorState } from '../../store/editorState';
import type { Dispatch } from 'types/redux';
import type { AppData } from 'types/documentServices';

const updateProgressBar = (editorAPI: EditorAPI, step: number) => {
  const updateSteps: { title?: string; stepTitle?: string }[] = [
    { title: 'AppStudio_Update_Popup_Loader_Title' },
    { stepTitle: 'AppStudio_Update_Popup_Loader_Dependencies_Text4' },
    { stepTitle: 'AppStudio_Update_Popup_Loader_Text' },
  ];

  if (step === 0) {
    editorAPI.panelHelpers.openProgressBar({
      title: translate(updateSteps[step]?.title),
      totalSteps: 3,
    });
  } else {
    editorAPI.panelHelpers.updateProgressBar(
      step,
      translate(updateSteps[step]?.stepTitle),
    );
  }
};

const updateFakeProgressBar = (
  editorAPI: EditorAPI,
  isDone: boolean,
  appName?: string,
) => {
  if (isDone) {
    editorAPI.panelManager.updatePanelProps(
      constants.PANELS.FAKE_PROGRESS_BAR,
      {
        isDone: true,
      },
    );
  } else {
    editorAPI.panelManager.openPanel(constants.PANELS.FAKE_PROGRESS_BAR, {
      appName,
      isDone: false,
    });
  }
};

const saveInBackground = (editorAPI: EditorAPI, isRemove?: boolean) =>
  new Promise<void>((resolve, reject) => {
    const save = () => {
      editorAPI.saveManager.saveInBackground(
        () => {
          editorAPI.history.add(
            `Application ${isRemove ? 'removed' : 'installed'}`,
            { shouldSave: true },
          );
          resolve();
        },
        reject,
        `Platform ${isRemove ? 'uninstall' : 'install'}`,
      );
    };
    if (!editorAPI.savePublish.canSaveOrPublish()) {
      editorAPI.editorEventRegistry.registerOnce(
        editorAPI.editorEventRegistry.constants.events.SAVE_PUBLISH_UNLOCKED,
        save,
      );
    } else {
      save();
    }
  });

const openInstallerBuilderPanel = (
  editorAPI: EditorAPI,
  dependenciesData: InstallerAppsData[],
  appToInstallName: string,
): Promise<PanelResolveType> =>
  new Promise((resolve) => {
    editorAPI.panelManager.openPanel(
      'platformPanels.InstallerAppsPanelComponent',
      {
        appToInstallName,
        dependenciesData,
        onPanelClose: resolve,
      },
      false,
    );
  });

const handleInstallationAndUpdate =
  (
    appDefinitionId: string,
    appsToInstallData: InstallerAppsToInstall,
    options: InstallAppOptions,
    appName: string,
    callbacks?: InstallAppCallbacks,
    isUpdateFlow?: boolean,
  ) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ): Promise<void> => {
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    const { skipActiveApps, shouldOpenFakeProgressBar, ...otherOptions } =
      options;

    const isSilentRunning =
      editorAPI.platform.applications.isSilentInstallRunning();

    const hasDependencies = appsToInstallData.appsToInstall.some(
      (appDefId) => appDefId !== appDefinitionId,
    );

    const isProgressBarOpen = editorAPI.panelHelpers.isProgressBarOpen();

    const shouldOpenProgressBar =
      isUpdateFlow || isSilentRunning || isProgressBarOpen
        ? false
        : hasDependencies || shouldOpenFakeProgressBar;

    const onError = (
      error: Error,
      errName: string,
      appDefinitionId: string,
    ) => {
      if (shouldOpenProgressBar) {
        updateFakeProgressBar(editorAPI, true);
      }
      if (callbacks?.onError) {
        callbacks.onError({ error, errName, appDefinitionId });
      } else {
        options?.callback({ onError: { error, errName, appDefinitionId } });
      }
    };

    const singleAppCallback = callbacks?.onAppInstallationComplete
      ? (appDefId: string, data: AppData | AddAppData) => {
          if (appDefId === appDefinitionId) {
            if (shouldOpenProgressBar) {
              updateFakeProgressBar(editorAPI, true);
            }
            callbacks.onAppInstallationComplete(data);
          }
        }
      : () => {};

    if (isUpdateFlow) {
      await updateAppInstall(
        editorAPI,
        appDefinitionId,
        appsToInstallData,
        options,
        {
          singleAppCallback,
          onError,
        },
      );
    } else {
      if (shouldOpenProgressBar) {
        updateFakeProgressBar(editorAPI, false, appName);
      }
      platformAPI.applications.install(
        appsToInstallData.appsToInstall,
        {
          ...(skipActiveApps !== undefined ? { skipActiveApps } : {}),
          appsOptions: {
            [appDefinitionId]: otherOptions,
            ...appsToInstallData.dependencyOptions,
          },
        },
        {
          singleAppCallback,
          onError,
        },
      );
    }
  };

const updateAppInstall = async (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  appsToInstallData: InstallerAppsToInstall,
  options: InstallAppOptions,
  callbacks: UpdateAppCallbacks,
) => {
  updateProgressBar(editorAPI, 0);
  const appsToInstall = appsToInstallData.appsToInstall.filter(
    (appDefId) => appDefId !== appDefinitionId,
  );

  const { applicationId } =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);

  const { skipActiveApps, ...otherOptions } = options;

  const finishAllCallback = async () => {
    updateProgressBar(editorAPI, 2);
    try {
      const appData = await editorAPI.dsActions.platform.update(
        applicationId,
        otherOptions.appVersion,
        otherOptions?.payload,
      );
      callbacks.singleAppCallback(appDefinitionId, appData);
    } catch (e: MaybeError) {
      callbacks.onError(e, 'updateError', appDefinitionId);
    }
  };

  if (appsToInstall.length) {
    updateProgressBar(editorAPI, 1);
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    platformAPI.applications.install(
      appsToInstall,
      {
        ...(skipActiveApps !== undefined ? { skipActiveApps } : {}),
        appsOptions: {
          ...appsToInstallData.dependencyOptions,
        },
      },
      {
        onError: callbacks.onError,
        finishAllCallback,
      },
    );
  } else {
    await finishAllCallback();
  }
};

const installApp =
  (
    appDefinitionId: string,
    options: InstallAppOptions,
    callbacks?: InstallAppCallbacks,
    isUpdateFlow?: boolean,
  ) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ): Promise<void> => {
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

    try {
      const dependencyDriver: DependenciesDataDriver =
        await platformAPI.applications.createDependenciesDriver(
          appDefinitionId,
          options.appVersion,
        );

      // TODO remove optional filter when finish UX for optional dependencies and move it to the panel

      const driverData: InstallerData = dependencyDriver.getData();

      const dependenciesData: InstallerAppsData[] =
        driverData.dependenciesData.filter(
          (appDependencyData: InstallerAppsData) => {
            if (appDependencyData.hasOwnProperty('toggle')) {
              return false;
            }

            return appDependencyData.notify;
          },
        );

      if (dependenciesData.length) {
        const panelStatus = await openInstallerBuilderPanel(
          editorAPI,
          dependenciesData,
          driverData.appToInstallName,
        );
        if (panelStatus.status === 'mainActionClicked') {
          const appsToInstall = dependencyDriver.getAppsToInstall();
          dispatch(
            handleInstallationAndUpdate(
              appDefinitionId,
              appsToInstall,
              options,
              driverData.appToInstallName,
              callbacks,
              isUpdateFlow,
            ),
          );
        } else {
          if (callbacks?.onError) {
            return callbacks.onError({
              error: new Error('user cancel installation'),
              errName: 'user cancel installation',
              appDefinitionId,
            });
          }
          options?.callback('user cancel installation');
        }
      } else {
        const appsToInstall = dependencyDriver.getAppsToInstall();
        dispatch(
          handleInstallationAndUpdate(
            appDefinitionId,
            appsToInstall,
            options,
            driverData.appToInstallName,
            callbacks,
            isUpdateFlow,
          ),
        );
      }
    } catch (e) {
      if (callbacks?.onError) {
        return callbacks.onError({
          error: e as Error,
          errName: (e as Error).message,
          appDefinitionId,
        });
      }
      options?.callback(e);
    }
  };

const updateApp =
  ({
    applicationId,
    appVersion,
    payload,
  }: {
    applicationId: string;
    appVersion: string;
    payload?: { updateType: string };
  }) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    return new Promise((resolve, reject) => {
      const onError = () => {
        dispatch(
          panelsActions.updateOrOpenPanel('tpa.panels.updateErrorPanel'),
        );
        reject(false);
      };

      const onAppInstallationComplete = async () => {
        try {
          await saveInBackground(editorAPI);
          editorAPI.panelHelpers.closeProgressBar();
          resolve(true);
        } catch (e) {
          onError();
        }
      };

      const { appDefinitionId } =
        editorAPI.dsRead.platform.getAppDataByApplicationId(applicationId);

      dispatch(
        installApp(
          appDefinitionId,
          { appVersion, payload },
          { onError, onAppInstallationComplete },
          true,
        ),
      );
    });
  };

export { installApp, saveInBackground, updateApp };
