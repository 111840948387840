const SET_TPA_CACHED_INNER_ROUTES = 'set-tpa-cached-inner-routes';
const SET_CURRENT_TPA_INNER_ROUTE = 'set-current-tpa-innter-route';
const SET_TPA_INNER_ROUTES_FETCH_PROCESS = 'set-tpa-inner-routes-fetch-process';
const RESET_TPA_CACHED_INNER_ROUTES = 'reset-tpa-cached-inner-routes';
const SET_FEATURES_SUB_PAGES_TO_IGNORE = 'set-features-sub-pages-to-ignore';

export {
  SET_TPA_CACHED_INNER_ROUTES,
  SET_CURRENT_TPA_INNER_ROUTE,
  SET_TPA_INNER_ROUTES_FETCH_PROCESS,
  RESET_TPA_CACHED_INNER_ROUTES,
  SET_FEATURES_SUB_PAGES_TO_IGNORE,
};
