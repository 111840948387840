import type { EditorAPI } from '@/editorAPI';
import type { TpaInnerRouteObject } from '@wix/document-services-types';
import type { Dispatch } from 'types/redux';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';
import * as actionTypes from './tpaDynamicPagesActionTypes';
import type {
  IgnoreSubPagesFeature,
  TpaInnerRouteData,
  TpaSubPagesInnerRoutes,
} from './tpaDynamicPagesReducer';
import * as selectors from './tpaDynamicPagesSelectors';
import type { EditorState } from '@/stateManagement';

type FetchedInnerRoutesCallback = (innerRoutes: TpaInnerRouteData[]) => void;

const setCachedInnerRoutes = (
  appDefinitionId: string,
  pageId: string,
  subPage: string,
  innerRoutes: TpaInnerRouteData[],
) => ({
  type: actionTypes.SET_TPA_CACHED_INNER_ROUTES,
  appDefinitionId,
  pageId,
  subPage,
  innerRoutes,
});

const setCurrentInnerRoute = (tpaInnerRouteInfo: {
  appDefinitionId?: string;
  pageId?: string;
  selectedInnerRoute?: TpaInnerRouteData;
}) => ({
  type: actionTypes.SET_CURRENT_TPA_INNER_ROUTE,
  ...tpaInnerRouteInfo,
});

const setIsInFetchProcess = (inInnerRoutesFetchProcess: boolean) => ({
  type: actionTypes.SET_TPA_INNER_ROUTES_FETCH_PROCESS,
  inInnerRoutesFetchProcess,
});

const navigateToInnerRoute =
  (
    appDefinitionId: string,
    pageId: string,
    selectedInnerRoute: TpaInnerRouteData,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    dispatch(
      setCurrentInnerRoute({
        appDefinitionId,
        pageId,
        selectedInnerRoute,
      }),
    );
    editorAPI.pages.navigateTo({
      innerRoute: `.${selectedInnerRoute.path}`,
      routerId: pageId,
      isTpaRoute: true,
      type: 'DynamicPageLink',
    });
  };

const cacheFeaturesSubPagesToIgnore =
  (appDefinitionId: string) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const appManifest = editorAPI.platform.getAppManifest(appDefinitionId);
    // @ts-expect-error
    const subPagesToIgnore = appManifest?.pages?.subPagesToHide;

    dispatch({
      type: actionTypes.SET_FEATURES_SUB_PAGES_TO_IGNORE,
      appDefinitionId,
      subPagesToIgnore,
    });

    return subPagesToIgnore;
  };

const setDefaultInnerRouteIfNeeded =
  (innerRoutes: TpaInnerRouteData[]) =>
  (dispatch: Dispatch, getState: () => EditorState) => {
    const { path, appDefinitionId, pageId } = selectors.getCurrentRouteInfo(
      getState(),
    );
    const allInnerRoutes = selectors.getAllSubPagesInnerRoutes(
      getState(),
      appDefinitionId,
      pageId,
    );
    if (path && allInnerRoutes.find((innerRoute) => innerRoute.path === path)) {
      return;
    }
    if (innerRoutes.length > 0) {
      dispatch(
        navigateRelativeUrl({
          relativeUrl: innerRoutes[0].path,
        }),
      );
    }
  };

const fetchCacheInnerRoutes =
  (
    appDefinitionId: string,
    pageId: string,
    tpaSubPages: string[],
    feature: IgnoreSubPagesFeature,
    callback?: FetchedInnerRoutesCallback,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const ignoredSubPagesInFeature =
      dispatch(cacheFeaturesSubPagesToIgnore(appDefinitionId))?.[feature] ?? [];
    tpaSubPages.forEach((subPage) => {
      dispatch(setCachedInnerRoutes(appDefinitionId, pageId, subPage, null));
    });

    dispatch(setIsInFetchProcess(true));

    const innerRoutesForCallback: TpaSubPagesInnerRoutes = tpaSubPages.reduce(
      (obj, subPage) => {
        obj[subPage] = [];
        return obj;
      },
      {} as TpaSubPagesInnerRoutes,
    );

    const getInnerRoutesPromises = tpaSubPages.map(
      (subPage) =>
        new Promise<void>((resolve) => {
          editorAPI.tpa.getTpaInnerRoutes(
            appDefinitionId,
            subPage,
            (results: TpaInnerRouteObject[]) => {
              const innerRoutes = results?.map((innerRoute) => ({
                ...innerRoute,
                subPage,
              }));

              if (!ignoredSubPagesInFeature.includes(subPage)) {
                innerRoutesForCallback[subPage] = innerRoutes;
              }
              dispatch(
                setCachedInnerRoutes(
                  appDefinitionId,
                  pageId,
                  subPage,
                  innerRoutes,
                ),
              );
              resolve();
            },
          );
        }),
    );

    const getAllInnerRoutesPromise = Promise.all(getInnerRoutesPromises);
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    getAllInnerRoutesPromise.then(() =>
      callback?.(Object.values(innerRoutesForCallback).flat(1)),
    );

    return Promise.race([getAllInnerRoutesPromise, timeoutPromise]).then(() => {
      dispatch(setIsInFetchProcess(false));
    });
  };

const fetchCacheInnerRoutesIfTpaDynamicPage =
  (
    appDefinitionId: string,
    pageId: string,
    options: {
      feature?: IgnoreSubPagesFeature;
      callback?: FetchedInnerRoutesCallback;
    },
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ): void => {
    if (!appDefinitionId) {
      return;
    }

    const failedToFetchSubPages = selectors.getSubPagesFailedToFetch(
      getState(),
      appDefinitionId,
      pageId,
    );

    if (failedToFetchSubPages && failedToFetchSubPages.length === 0) {
      return;
    }

    const tpaSubPages =
      failedToFetchSubPages ??
      editorAPI.tpa.page
        .getSubPages(pageId)
        .map(({ key }: { key: string }) => key);

    if (tpaSubPages && tpaSubPages.length > 0) {
      dispatch(
        fetchCacheInnerRoutes(
          appDefinitionId,
          pageId,
          tpaSubPages,
          options.feature,
          options.callback,
        ),
      );
    }
  };

const navigateRelativeUrl =
  (nextRoute: {
    relativeUrl: string;
    appDefinitionId?: string;
    pageId?: string;
  }) =>
  (dispatch: Dispatch, getState: () => EditorState) => {
    const { appDefinitionId, pageId } = Object.assign(
      {},
      selectors.getCurrentRouteInfo(getState()),
      nextRoute,
    );

    const selectedInnerRoute = selectors
      .getAllSubPagesInnerRoutes(getState(), appDefinitionId, pageId)
      .find(({ path }) => path === nextRoute.relativeUrl);

    if (!selectedInnerRoute) {
      return;
    }

    dispatch(navigateToInnerRoute(appDefinitionId, pageId, selectedInnerRoute));
  };

const adaptStateToCurrentPage =
  (feature: IgnoreSubPagesFeature) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const currentTpaInfo = selectors.getCurrentRouteInfo(getState());
    const currentPageId = editorAPI.pages.getCurrentPageId();
    const appData = editorAPI.pages.data.get(currentPageId);
    const appDefinitionId =
      appData?.appDefinitionId ?? appData?.managingAppDefId;

    if (currentTpaInfo?.pageId !== currentPageId) {
      dispatch(
        setCurrentInnerRoute({ appDefinitionId, pageId: currentPageId }),
      );
    }

    const callback = (innerRoutes: TpaInnerRouteData[]) =>
      dispatch(setDefaultInnerRouteIfNeeded(innerRoutes));

    dispatch(
      fetchCacheInnerRoutesIfTpaDynamicPage(appDefinitionId, currentPageId, {
        callback,
        feature,
      }),
    );

    const lastUpdatedInfo = selectors.getCurrentRouteInfo(getState());
    if (!lastUpdatedInfo.path) {
      callback(
        selectors.getFocusedInnerRoutes(
          getState(),
          lastUpdatedInfo.appDefinitionId,
          lastUpdatedInfo.pageId,
          feature,
        ),
      );
    }
  };

const fetchPageInnerRoutesInState =
  (
    pageId: string,
    feature?: IgnoreSubPagesFeature,
    callback?: FetchedInnerRoutesCallback,
  ) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    const appData = editorAPI.pages.data.get(pageId);
    const appDefinitionId =
      appData?.appDefinitionId ?? appData?.managingAppDefId;

    dispatch(
      fetchCacheInnerRoutesIfTpaDynamicPage(appDefinitionId, pageId, {
        feature,
        callback,
      }),
    );
  };

const clearInnerRoutesFromCache = (
  appDefinitionId: string,
  pageId: string,
) => ({
  type: actionTypes.RESET_TPA_CACHED_INNER_ROUTES,
  appDefinitionId,
  pageId,
});

const matchesGenericMemberUrl = (
  relativeUrl: string,
  innerRoutePath: string,
) => {
  const regexPattern = innerRoutePath.replace(/\/my\//, '/.*/');
  const regex = new RegExp(`^${regexPattern}$`);

  return regex.test(relativeUrl);
};

const findInnerRoute = (
  relativeUrl: string,
  innerRoutePath: string,
  appDefinitionId: string,
) =>
  appDefinitionId === MEMBERS_AREA_V2
    ? matchesGenericMemberUrl(relativeUrl, innerRoutePath)
    : innerRoutePath === relativeUrl;

const setCurrentUrlToState =
  (relativeUrl: string) =>
  (dispatch: Dispatch, getState: () => EditorState) => {
    if (!relativeUrl) {
      return;
    }
    const currentInfo = selectors.getCurrentRouteInfo(getState());
    if (currentInfo.path === relativeUrl) {
      return;
    }
    const { appDefinitionId } = currentInfo;

    const selectedInnerRoute = selectors
      .getAllSubPagesInnerRoutes(
        getState(),
        appDefinitionId,
        currentInfo.pageId,
      )
      .find(({ path }) => findInnerRoute(relativeUrl, path, appDefinitionId));

    dispatch(setCurrentInnerRoute({ ...currentInfo, selectedInnerRoute }));
  };

export {
  navigateRelativeUrl,
  adaptStateToCurrentPage,
  setCurrentUrlToState,
  clearInnerRoutesFromCache,
  fetchPageInnerRoutesInState,
};
