// @ts-nocheck
import _ from 'lodash';
import * as coreBi from '@/coreBi';
import * as selectionSelectors from './selectionSelectors';
import * as highlights from '../highlights/highlights';
import selectionActionTypes from './selectionActionTypes';
import * as bi from '../bi/bi';
import * as componentsSelectors from '../components/componentsSelectors';
import * as multilingualBi from '../multilingual/multilingualBi';
import * as util from '@/util';
import { interactionsSelectors } from '../interactions/interactionsSelectors';

const { event } = bi.actions;
const highlightsActions = highlights.actions;
const { isMultiselect } = util.array;
import type { CompRef } from 'types/documentServices';

const {
  SET_FOCUSED_CONTAINER,
  SET_FOCUSED_TABS_DEF,
  SET_LAST_SELECTION_CLICK_POS,
  SELECT_COMPONENTS,
  ADD_TO_SELECTION,
  REMOVE_FROM_SELECTION,
  SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION,
  CLEAR_SELECTED_COMPONENTS,
  SET_SELECTED_COMPS_RESTRICTIONS,
  SET_SELECTED_COMPS_DISPLAY_NAME,
  SET_APP_CONTAINER,
  SET_LAST_CLICK_TYPE,
  SET_PREV_SELECTED_COMPS,
} = selectionActionTypes;
const { getSelectedCompsRefs, getFocusedContainer, getContainerTabDefinition } =
  selectionSelectors;

const addToSelection = (compRefs) => ({
  type: ADD_TO_SELECTION,
  compRefs,
});

const removeFromSelection = (compRefs) => ({
  type: REMOVE_FROM_SELECTION,
  compRefs,
});

const setLastSelectionClickPos = (pos) => ({
  type: SET_LAST_SELECTION_CLICK_POS,
  pos,
});

const setFocusedContainer =
  (
    nextFocusedContainer,
    currentFocusedContainer?,
    callback?,
    biParams?: { origin: string },
  ) =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    dispatch(setFocusedContainerAction(nextFocusedContainer));
    dispatch(setFocusedTabDefinition());
    multilingualBi.translateGfppExposer(editorAPI);
    if (_.isFunction(callback)) {
      callback(dsRead, nextFocusedContainer, currentFocusedContainer);
    }
    sendGfppExposerBI(editorAPI, biParams);
  };

// Should not be here but translateGfppExposer is here for some reason and i cant think of a better place right now
// all of the optional chaining is there for the legacy tests
const sendGfppExposerBI = (editorAPI, biParams?: { origin: string }) => {
  const selectedComponents = editorAPI?.selection?.getSelectedComponents();
  const compRef = _.head(selectedComponents);
  const compType = editorAPI?.components.getType(compRef);
  const parentCompRef = editorAPI?.components.getContainer(compRef);
  const parentComponentType = editorAPI?.components.getType(parentCompRef);
  const pageId = editorAPI?.pages.getCurrentPage()?.id;
  const multiselect = isMultiselect(selectedComponents);
  const inZoomMode = editorAPI?.zoomMode.isInZoomMode();

  const isParentContainerComponent =
    parentCompRef && editorAPI?.components.is.container(parentCompRef);

  const isParentWithInteraction =
    editorAPI &&
    parentCompRef &&
    interactionsSelectors.componentHasInteraction(editorAPI, parentCompRef);

  const isParentHoverBoxComponent =
    isParentContainerComponent && isParentWithInteraction;

  if (compRef && !inZoomMode) {
    const isWithinBlocksWidget =
      componentsSelectors.isReferredId(compRef?.id) &&
      componentsSelectors.isDescendantOfBlocksWidget(compRef, editorAPI.dsRead);
    editorAPI?.bi.event(coreBi.events.text.gfpp_Buttons_show, {
      ...editorAPI.bi.getComponentsBIParams([compRef])[0],
      component_id: compRef?.id,
      component_type: multiselect ? 'MultiSelect' : compType,
      parentComponentId: parentCompRef?.id ?? 'null',
      parentComponentType: parentComponentType ?? 'null',
      pageId,
      is_parent_hover: isParentHoverBoxComponent,
      origin: biParams?.origin,
      isWithinBlocksWidget,
    });
  }
};

const setFocusedContainerAction = (focusedContainer) => ({
  type: SET_FOCUSED_CONTAINER,
  focusedContainer,
});

const setAppContainer = (appContainer) => ({
  type: SET_APP_CONTAINER,
  appContainer,
});

const selectComponentsAction = (compRefs) => ({
  type: SELECT_COMPONENTS,
  compRefs,
});

const setPrevComponentsAction = (compRefs) => ({
  type: SET_PREV_SELECTED_COMPS,
  compRefs,
});

const clearSelectedComponents = () => ({
  type: CLEAR_SELECTED_COMPONENTS,
});

const setSelectedCompsRestrictions = (restrictions) => ({
  type: SET_SELECTED_COMPS_RESTRICTIONS,
  restrictions,
});

const setSelectedCompsDisplayName = (compDisplayName) => ({
  type: SET_SELECTED_COMPS_DISPLAY_NAME,
  compDisplayName,
});

const selectComponents =
  (compsToSelect: CompRef[], compRestrictions?) =>
  (dispatch, getState, { dsRead }) => {
    const currentSelected = getSelectedCompsRefs(getState());

    if (_.isEqual(currentSelected, compsToSelect)) {
      return;
    }
    dispatch(selectComponentsAction(compsToSelect));
    dispatch(highlightsActions.handleSelectionHighlights());
    dispatch(setSelectedCompsRestrictions(compRestrictions));
    const compDisplayName = isMultiselect(compsToSelect)
      ? ''
      : componentsSelectors.getPlatformDisplayName(
          _.head(compsToSelect),
          dsRead,
        ) || '';
    dispatch(setSelectedCompsDisplayName(compDisplayName));
  };

const sendBiOnSelectPopupOverlay =
  (components) =>
  (dispatch, getState, { dsRead }) => {
    if (!components) {
      return;
    }
    if (
      components.length === 1 &&
      dsRead.pages.popupPages.isPopup(components[0].id)
    ) {
      dispatch(event(coreBi.events.popups.pop_up_overlay_clicked));
    }
  };

const setFocusedTabDefinition =
  () =>
  (dispatch, getState, { dsRead, editorAPI }) => {
    const state = getState();
    const focusedContainer = getFocusedContainer(state);
    if (!focusedContainer) {
      return dispatch(setFocusedTabsDefinitionAction(null));
    }

    const tabsDef = getContainerTabDefinition(
      focusedContainer,
      dsRead,
      getState(),
      editorAPI,
    );
    return dispatch(setFocusedTabsDefinitionAction(tabsDef));
  };

const setFocusedTabsDefinitionAction = (tabsDef) => ({
  type: SET_FOCUSED_TABS_DEF,
  tabsDef,
});

const setAncestorOfSelectedWithInteraction = (
  ancestorOfSelectedWithInteraction: CompRef | null,
) => ({
  type: SET_ANCESTOR_OF_SELECTED_WITH_INTERACTION,
  ancestorOfSelectedWithInteraction,
});

const setLastClickType = (clickType: 'left' | 'right') => ({
  type: SET_LAST_CLICK_TYPE,
  clickType,
});

export {
  addToSelection,
  removeFromSelection,
  selectComponentsAction,
  clearSelectedComponents,
  setAncestorOfSelectedWithInteraction,
  setSelectedCompsRestrictions,
  selectComponents,
  setLastSelectionClickPos,
  setFocusedContainer,
  setAppContainer,
  setFocusedTabDefinition,
  sendBiOnSelectPopupOverlay,
  setLastClickType,
  setPrevComponentsAction,
};
