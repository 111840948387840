//@ts-nocheck
import { translate } from '@/i18n';
import type { EditorAPI } from '@/editorAPI';
import * as util from '@/util';
import { DATA_BINDING } from '@wix/app-definition-ids';
import _ from 'lodash';
import type {
  CompRef,
  DSRead,
  PlatformAppDescription,
  PageRef,
} from 'types/documentServices';
import * as componentsSelectors from '../components/componentsSelectors';
import { createSelector } from 'reselect';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import experiment from 'experiment';
import { editorModel } from '@wix/santa-editor-utils';

const { isRefComponent, isAppWidget } = componentsSelectors;

const { isWidgetController } = util.appStudioUtils;

export interface EssentialData {
  isEssential: boolean;
  text?: string;
}
const isSilentInstallRunning = (state): boolean =>
  state.platform.silentInstall.isRunning;

const isUsedSilentInstallOnSite = (state): boolean =>
  state.platform.silentInstall.usedOnSite;

const getLatestVersions: (state: EditorState) => Record<string, string> = (
  state,
) => state.platform.latestVersions;
const getAvailableApps = (state) => state.platform.availableApps;
const getFreshAppsData = (state) => state.platform.freshAppsData;
const getIsLoading = (state) => state.platform.importPanel.isLoading;
const getMasterStructure = (state) =>
  state.platform.addElementsPanel.masterStructure;
const getIsApplicationInstallationStatus = (state) =>
  state.platform.appInstallationInProcess;
const getIsLoadingAppsError = (state) =>
  state.platform.importPanel.isLoadingAppsError;
const getIsPackagesViewRootExpanded = (state) =>
  state.platform.packagesView.isRootExpanded;
const getIsImportingApp = (state) => state.platform.packagesView.isImportingApp;
const doesHaveAppsToMeasureLoadTime = (state) =>
  Object.keys(state.platform.loadBiEvents.loadTimeAppsManifestLoadedIndicator)
    .length > 0;
const haveAllAppManifestsBeenLoaded = (state) =>
  Object.values(
    state.platform.loadBiEvents.loadTimeAppsManifestLoadedIndicator,
  ).every(Boolean);
const getLoadedAppManifestsCount = (state) =>
  Object.values(
    state.platform.loadBiEvents.loadTimeAppsManifestLoadedIndicator,
  ).filter(Boolean).length;
const hasAllAppManifestsLoadedReported = (state) =>
  state.platform.loadBiEvents.hasAllAppManifestsLoadedReported;
const hasEditorScriptsLoadedReported = (state) =>
  state.platform.loadBiEvents.hasEditorScriptsLoadedReported;
const hasStartLoadingPlatformBeenReported = (state) =>
  state.platform.loadBiEvents.hasStartLoadingPlatformBeenReported;
const hasStartLoadingEditorScriptsBeenReported = (state) =>
  state.platform.loadBiEvents.hasStartLoadingEditorScriptsBeenReported;

const isConnectedToDataBindingController = (
  compRef: CompRef,
  dsRead: DSRead,
): boolean =>
  _(compRef)
    .thru(dsRead.platform.controllers.connections.get)
    .map('controllerRef')
    .thru((controllers) =>
      componentsSelectors.getCompsData(controllers, dsRead),
    )
    .some({ applicationId: DATA_BINDING });

const isDataBindingAppProvisioned = (dsRead: DSRead) => {
  const wixCodeSiteExtensionAppDefId =
    dsRead.platform.editorApps.WIX_CODE.appDefId;
  return !!dsRead.platform.getAppDataByAppDefId(wixCodeSiteExtensionAppDefId);
};

const getWidgetsDataFromManifest = (
  dsRead: DSRead,
  appDefinitionId: string,
) => {
  const appManifest = dsRead.platform.getAppManifest(appDefinitionId);
  return appManifest?.widgets;
};

const getAddPanelCategoryDefinitionFromManifest = (
  dsRead: DSRead,
  appDefinitionId: string,
) => {
  const appManifest = dsRead.platform.getAppManifest(appDefinitionId);
  return appManifest?.addPanelSectionDefinition;
};

const hasWidgets = (dsRead: DSRead, appDefinitionId: string): boolean => {
  const widgets = getWidgetsDataFromManifest(dsRead, appDefinitionId);
  return !_.isEmpty(widgets);
};

const doesAppHaveComponent = (app: PlatformAppDescription, type: string) => {
  return app.components && app.components.some((x) => x.type === type);
};

const isBlocksCodePackageApp = (app: PlatformAppDescription) =>
  doesAppHaveComponent(app, 'STUDIO') &&
  doesAppHaveComponent(app, 'CODE_PACKAGE');

const getInstalledPackages = (dsRead: DSRead): PlatformAppDescription[] => {
  const installedApps = dsRead.platform.getInstalledEditorApps();
  return !_.isEmpty(installedApps)
    ? installedApps.filter(
        (installedApp) =>
          hasWidgets(dsRead, installedApp.appDefinitionId) ||
          isBlocksCodePackageApp(installedApp),
      )
    : [];
};

const getEssentialData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): EssentialData => {
  const isRefComp = isRefComponent(compRef, editorAPI.dsRead);
  const stageData = isRefComp
    ? editorAPI.dsRead.appStudioWidgets.getStageData(compRef)
    : editorAPI.dsRead.platform.controllers.getConnectedComponentStageData(
        compRef,
      );

  const compName = editorAPI.components.getDisplayName(compRef);
  const controllerRef =
    editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
      compRef,
    )?.controllerRef;

  return getEssentialDataFromManifest(
    editorAPI,
    stageData,
    compName,
    controllerRef,
  );
};

const getEssentialDataByRole = (
  editorAPI: EditorAPI,
  controllerRef: CompRef,
  role: string,
): EssentialData => {
  const stageData = editorAPI.dsRead.platform.controllers.getStageDataByRole(
    controllerRef,
    role,
  );
  return getEssentialDataFromManifest(
    editorAPI,
    stageData,
    role,
    controllerRef,
  );
};

const getEssentialDataFromManifest = (
  editorAPI: EditorAPI,
  stageData: any,
  compName: string,
  controllerRef: CompRef,
): EssentialData => {
  const essentialData = stageData?.behavior?.essential;
  if (!essentialData?.enabled) {
    return {
      isEssential: false,
    };
  }

  const { text } = essentialData;

  return {
    isEssential: true,
    text: text || getDefaultEssentialText(editorAPI, compName, controllerRef),
  };
};

const getDefaultEssentialText = (
  editorAPI: EditorAPI,
  compName: string,
  controllerRef: CompRef,
): string => {
  const widgetName =
    controllerRef && editorAPI.components.getDisplayName(controllerRef);
  return translate('AppStudio_Essential_Elements_Missing_Shared_Tooltip', {
    Element_Name: compName,
    Widget_Name: widgetName,
  });
};

const isRefComponentSupported = (editorAPI: EditorAPI): boolean => {
  // `santa` is currently used in the old unit tests testkit
  return editorAPI.dsRead.env?.viewer.getViewerName() !== 'santa';
};

const hasPlatformAddElements = (editorAPI: EditorAPI, compRef: CompRef) => {
  const compData = editorAPI.components.data.get(compRef);
  if (!compData) {
    return false;
  }
  const appDefId = compData.applicationId || compData.appDefinitionId;
  const appManifest = editorAPI.dsRead.platform.getAppManifest(appDefId);
  if (!appManifest) {
    return false;
  }
  const widgetId = JSON.parse(compData.settings || '{}')?.type;
  const controllersStageData = appManifest.controllersStageData || {};
  return Boolean(
    controllersStageData[`${appDefId}-${widgetId}`]?.default?.gfpp?.desktop
      ?.iconButtons?.add,
  );
};

const shouldWidgetHaveAddElements = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  if (!isAppWidget(compRef, editorAPI.dsRead)) {
    return false;
  }
  // TODO: we will need to remove this when specs.universalEditorApp.PlatformAddElementsPanel is merged. #WBL-473
  if (hasPlatformAddElements(editorAPI, compRef)) {
    return false;
  }
  const [widgetRootRef] = editorAPI.components.getChildren(compRef);
  const isContainer =
    widgetRootRef && editorAPI.dsRead.components.is.container(widgetRootRef);
  if (!isContainer) {
    return false;
  }

  if (isWidgetController(editorAPI, compRef)) {
    return true;
  }

  if (!util.appStudioUtils.isAppStudio()) {
    return false;
  }

  const parentRef = editorAPI.components.getContainer(compRef);
  const isInsidePage =
    editorAPI.components.getType(parentRef) === 'mobile.core.components.Page';
  return !isInsidePage;
};

const packagesView = {
  getIsImportingApp,
  getIsRootExpanded: getIsPackagesViewRootExpanded,
};

const getInstalledAppsDependency = (dsRead: DSRead, appDefinitionId: string) =>
  dsRead.platform
    .getInstalledApps()
    .reduce((installedAppsDependencyInfo, appData) => {
      const appDependencyInfo = dsRead.platform
        .getAppsDependenciesWithPredicate(
          [appData.appDefinitionId],
          (dependency) =>
            dsRead.platform.isAppActive(dependency.appDefinitionId),
        )
        .find((app) => app.appDefinitionId === appDefinitionId);
      if (appDependencyInfo) {
        installedAppsDependencyInfo.push({
          appDefinitionId: appData.appDefinitionId,
          isRequired: appDependencyInfo.isRequired || false,
        });
      }
      return installedAppsDependencyInfo;
    }, []);

const selectSections = (_, props) => props.sectionsData;

const selectCategories = (_, props) => props.categoriesData;
const selectItems = (_, props) => {
  return { elementsData: props.elementsData, widgetRef: props.widgetRef };
};
const sortItemsHandler = ({ index: indexOne }, { index: indexTwo }) =>
  indexOne - indexTwo;
const sortItems = (normalizedItems) => {
  // sort items with section
  Object.keys(normalizedItems.sectionsWithItems).forEach((sectionKey) => {
    normalizedItems.sectionsWithItems[sectionKey].unCategorizedItems.sort(
      sortItemsHandler,
    );
    Object.keys(
      normalizedItems.sectionsWithItems[sectionKey].categoriesWithItems,
    ).forEach((categoryKey) => {
      normalizedItems.sectionsWithItems[sectionKey].categoriesWithItems[
        categoryKey
      ].children.sort(sortItemsHandler);
    });
  });
  // sort items without section
  normalizedItems.notInSectionItems.unCategorizedItems.sort(sortItemsHandler);
  Object.keys(normalizedItems.notInSectionItems.categoriesWithItems).forEach(
    (categoryKey) => {
      normalizedItems.notInSectionItems.categoriesWithItems[
        categoryKey
      ].children.sort(sortItemsHandler);
    },
  );
  return normalizedItems;
};
const setCompRefAndCheckedStatus = (
  item,
  getIdentifierFromRoleAndSubRole,
  data,
) => {
  const id = getIdentifierFromRoleAndSubRole(item.identifier);
  if (item.compRef) {
    data.checkedState[id] = !item.isCollapsed;
    data.compRefsMap[id] = item.compRef;
  }

  return data;
};
const getSelectItemsByCategory = (
  editorAPI: EditorAPI,
  findConnectionByRole,
  getIdentifierFromRoleAndSubRole,
) =>
  createSelector(
    [selectSections, selectCategories, selectItems],
    (sections, categories, elements) => {
      let data = {
        checkedState: {},
        compRefsMap: {},
      };
      const primaryConnectionOfConnected =
        editorAPI.dsRead.platform.controllers.connections
          .getConnectedComponents(elements.widgetRef, true)
          .map((compRef) => {
            const primaryConnection =
              editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
                compRef,
              );
            return { ...primaryConnection, compRef };
          });
      const normalizedItems = elements.elementsData.reduce(
        (acc, item) => {
          const fullItem = { ...item };
          let { compRef } =
            findConnectionByRole(
              item.identifier,
              primaryConnectionOfConnected,
            ) || {};
          if (compRef) {
            if (isAppWidget(compRef, editorAPI.dsRead)) {
              compRef =
                editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
                  compRef,
                );
            }
            const properties =
              editorAPI.dsRead.components.properties.get(compRef);
            fullItem.isCollapsed = properties?.ghost === 'COLLAPSED';
            const compBehavior =
              editorAPI.dsRead.platform.controllers.getConnectedComponentStageData(
                compRef,
              )?.behavior;
            fullItem.isNonRemovable = compBehavior?.preventHide;
            fullItem.compRef = compRef;
          }
          data = setCompRefAndCheckedStatus(
            fullItem,
            getIdentifierFromRoleAndSubRole,
            data,
          );

          fullItem.displayName =
            item.label ||
            (compRef && editorAPI.components.getDisplayName(compRef));
          /// items with section
          if (item.sectionId) {
            if (!acc.sectionsWithItems[item.sectionId]) {
              const sectionIndex = sections?.findIndex(
                ({ id }) => id === item.sectionId,
              );
              acc.sectionsWithItems[item.sectionId] = {
                ...sections[sectionIndex],
                unCategorizedItems: [],
                categoriesWithItems: {},
              };
            }
            // an item with section & category
            if (item.categoryId) {
              if (
                !acc.sectionsWithItems[item.sectionId].categoriesWithItems[
                  item.categoryId
                ]
              ) {
                const categoryIndex = categories.findIndex(
                  ({ id }) => id === item.categoryId,
                );
                acc.sectionsWithItems[item.sectionId].categoriesWithItems[
                  item.categoryId
                ] = {
                  ...categories[categoryIndex],
                  children: [],
                };
              }
              acc.sectionsWithItems[item.sectionId].categoriesWithItems[
                item.categoryId
              ].children.push(fullItem);
              // an item with section (without category)
            } else {
              acc.sectionsWithItems[item.sectionId].unCategorizedItems.push(
                fullItem,
              );
            }
            /// items without section
            // an item with category (without section)
          } else if (item.categoryId) {
            if (!acc.notInSectionItems.categoriesWithItems[item.categoryId]) {
              const categoryIndex = categories.findIndex(
                ({ id }) => id === item.categoryId,
              );
              acc.notInSectionItems.categoriesWithItems[item.categoryId] = {
                ...categories[categoryIndex],
                children: [],
              };
            }
            acc.notInSectionItems.categoriesWithItems[
              item.categoryId
            ].children.push(fullItem);
            // an item without section & without category
          } else {
            acc.notInSectionItems.unCategorizedItems.push(fullItem);
          }
          return acc;
        },
        {
          sectionsWithItems: {},
          notInSectionItems: {
            unCategorizedItems: [],
            categoriesWithItems: {},
          },
        },
      );
      return { items: sortItems(normalizedItems), data };
    },
  );

const isAppWithReplacerPage = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
) => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const appPagesData = editorAPI.dsRead.pages
    .getPagesData()
    .filter((data) => data?.managingAppDefId === appDefinitionId);
  return appPagesData.some((pageData) =>
    platformAPI.pageReplace.isReplacer(
      editorAPI.dsRead.pages.getReference(pageData.id) as PageRef,
    ),
  );
};

// since this day we decided to install pending page apps silently
// we do not want to install pending for old sites
const startingPoint = new Date('05/01/23').getTime();
const shouldInstallPendingSilently = (editorAPI: EditorAPI) => {
  const isNewSite = startingPoint < editorModel.siteHeader.dateCreated;

  const usedSilentInstall = isUsedSilentInstallOnSite(
    editorAPI.store.getState(),
  );

  return (
    util.sections.isSectionsEnabled() &&
    experiment.isOpen('se_installPendingAppsOnSilentInstall') &&
    (usedSilentInstall || isNewSite)
  );
};

export {
  isConnectedToDataBindingController,
  isDataBindingAppProvisioned,
  getIsLoading,
  getIsLoadingAppsError,
  getAvailableApps,
  getFreshAppsData,
  getWidgetsDataFromManifest,
  getAddPanelCategoryDefinitionFromManifest,
  getInstalledPackages,
  shouldInstallPendingSilently,
  getIsApplicationInstallationStatus,
  doesHaveAppsToMeasureLoadTime,
  haveAllAppManifestsBeenLoaded,
  getLoadedAppManifestsCount,
  hasAllAppManifestsLoadedReported,
  hasEditorScriptsLoadedReported,
  hasStartLoadingPlatformBeenReported,
  hasStartLoadingEditorScriptsBeenReported,
  getMasterStructure,
  getEssentialData,
  getEssentialDataByRole,
  shouldWidgetHaveAddElements,
  isRefComponentSupported,
  getInstalledAppsDependency,
  packagesView,
  getSelectItemsByCategory,
  isSilentInstallRunning,
  getLatestVersions,
  isAppWithReplacerPage,
};
