import _ from 'lodash';
import actionTypes from './notificationsActionTypes';
import * as notificationsSelectors from './notificationsSelectors';
import * as coreBi from '@/coreBi';
import * as bi from '../bi/bi';
import constants from '@/constants';

import { notificationSoundPlayerFactory } from './createNoticationSoundPlayer';

import type {
  Notification,
  NotificationsState,
  NotificationType,
} from './notificationsTypes';
import type { EditorState } from '../store/editorState';

import type { ThunkAction, Dispatch } from 'types/redux';

const notificationSound = notificationSoundPlayerFactory();

function shouldNotificationBeShown(
  getState: () => EditorState,
  dispatch: Dispatch,
  message: string,
) {
  const notificationInstancesOnScreen = _.findKey(getState().notifications, [
    'message',
    message,
  ]);
  if (notificationInstancesOnScreen) {
    return false;
  }

  if (getState().notifications.length === 3) {
    const { notifications } = getState();
    const oldestNotification = _.minBy(notifications, 'startTime');
    dispatch(
      bi.actions.event(coreBi.events.notifications.editor_notification_close, {
        time_since_load: _.now() - oldestNotification.startTime,
        finish_method: 'oldest_from_three_on_screen',
        topic: oldestNotification.title,
      }),
    );
    _.invoke(oldestNotification, 'onClose');
    dispatch(closeNotification(oldestNotification.id));
  }
  return true;
}

function setNotificationTimer(
  dispatch: Dispatch,
  getState: () => EditorState,
  notification: Notification,
  timeout: number = constants.NOTIFICATIONS.TIMEOUT,
) {
  return window.setTimeout(() => {
    const { notifications } = getState();
    const index = notifications.findIndex(
      (item) => item.id === notification.id,
    );

    if (index !== -1) {
      dispatch(
        bi.actions.event(
          coreBi.events.notifications.editor_notification_close,
          {
            time_since_load: _.now() - notifications[index].startTime,
            finish_method: 'time_out',
            topic: notification.title,
          },
        ),
      );
      _.invoke(notification, 'onClose');
      dispatch(closeNotification(notification.id));
    }
  }, timeout);
}

const closeNotification = (id: string) => ({
  type: actionTypes.CLOSE_NOTIFICATION,
  id,
});

const closeAllNotifications = () => ({
  type: actionTypes.CLOSE_ALL_NOTIFICATIONS,
});

const resetNotificationTimerById =
  (
    notification: Notification,
    notificationState: NotificationsState,
    timerId: number,
    index: number,
    startTime: number,
  ): ThunkAction =>
  (dispatch) => {
    dispatch({
      type: actionTypes.RESET_NOTIFICATION_TIMER,
      timerId,
      notification,
      index,
      startTime,
      notificationState: _.cloneDeep(notificationState),
    });
  };

const clearNotificationTimer = (
  notification: Notification,
  notificationState: NotificationsState,
) => ({
  type: actionTypes.CLEAR_NOTIFICATION_TIMER,
  notification,
  notificationState: _.clone(notificationState),
});

const addNotification = (notification: Notification) => ({
  type: actionTypes.ADD_NOTIFICATION,
  notification,
});

const resetNotificationTimer =
  <T extends Notification>(
    notification: T,
    notificationState: NotificationsState,
    index: number,
  ): ThunkAction =>
  (dispatch, getState) => {
    const timerId = window.setTimeout(() => {
      const state = getState();
      const notifications = notificationsSelectors.selectNotifications(state);
      const indexAfterTimeout = notifications.findIndex(
        (item) => item?.id === notification.id,
      );

      if (indexAfterTimeout !== -1) {
        dispatch(
          bi.actions.event(
            coreBi.events.notifications.editor_notification_close,
            {
              time_since_load:
                _.now() - notifications[indexAfterTimeout].startTime,
              finish_method: 'time_out',
              topic: notification.title,
            },
          ),
        );
        _.invoke(notification, 'onClose');
        dispatch(closeNotification(notification.id));
      }
    }, constants.NOTIFICATIONS.TIMEOUT);
    const startTime = _.now();
    dispatch(
      resetNotificationTimerById(
        notification,
        notificationState,
        timerId,
        index,
        startTime,
      ),
    );
  };

export interface ShowUserActionNotificationParam {
  message: string;
  type: NotificationType;
  title?: string;
  origin?: string;
  link?: {
    caption?: string;
    onNotificationLinkClick?: () => void;
    param?: any;
  };
  symbolName?: string;
  shouldTranslate?: boolean;
  timeout?: number;
}

const showUserActionNotification =
  ({
    title,
    message,
    type,
    origin,
    link,
    symbolName,
    shouldTranslate,
    timeout,
  }: ShowUserActionNotificationParam): ThunkAction =>
  (dispatch, getState) => {
    if (shouldNotificationBeShown(getState, dispatch, message)) {
      const notification: Notification = {
        id: _.uniqueId('notification-'),
        name: 'notifications.editorNotification',
        title,
        message,
        type,
        origin,
        symbolName,
        shouldTranslate,
      };

      if (link?.caption) {
        notification.linkAction = {
          caption: link.caption,
          onClick: link.onNotificationLinkClick,
          param: link.param,
        };
      }

      notification.timerId = setNotificationTimer(
        dispatch,
        getState,
        notification,
        timeout,
      );
      notification.startTime = _.now();
      notificationSound.play();
      dispatch(addNotification(notification));
    }
  };

interface ErrorNotification {
  error: Error;
  methodName: string;
}

const showErrorNotification =
  ({ error, methodName }: ErrorNotification) =>
  (dispatch: Dispatch) => {
    const notification = {
      methodName,
      message: error.message,
      id: _.uniqueId('notification-'),
      name: 'notifications.errorNotification',
    };
    notificationSound.play();
    dispatch(addNotification(notification));
  };

const showTPAUserActionNotification =
  (notification: Notification): ThunkAction =>
  (dispatch, getState) => {
    if (shouldNotificationBeShown(getState, dispatch, notification.message)) {
      notification.timerId = setNotificationTimer(
        dispatch,
        getState,
        notification,
      );
      notification.startTime = _.now();
      notificationSound.play();
      dispatch(addNotification(notification));
    }
  };

const showWarningNotification =
  ({ message }: { message: string }): ThunkAction =>
  (dispatch) => {
    const notification = {
      message,
      type: constants.NOTIFICATIONS.TYPES.WARNING,
      id: _.uniqueId('notification-'),
      name: 'notifications.editorNotification',
      shouldTranslate: false,
    };
    notificationSound.play();
    dispatch(addNotification(notification));
  };

export {
  closeNotification,
  closeAllNotifications,
  addNotification,
  showErrorNotification,
  clearNotificationTimer,
  resetNotificationTimer,
  showUserActionNotification,
  showTPAUserActionNotification,
  showWarningNotification,
};
